import React from "react"
import styled from "styled-components"

import AccountWrapper from "../../components/account/account-wrapper"
import ShippingAddress from "../../components/account/address/shipping-address"

const ShippingDetails = ({ location }) => {
  return (
    <AccountWrapper location={location} accountStep="shipping-details">
      <StyledH2>Shipping Details</StyledH2>
      <ShippingAddress key="info-panel" />
    </AccountWrapper>
  )
}

export default ShippingDetails

// ===============
//     STYLES
// ===============
const StyledH2 = styled.h2`
  padding-bottom: 20px;
`
